import { useState } from 'react';
import {Link} from 'react-router-dom';

const menuItems =(
    <>
        <li class='mr-6 font-thin'>
            <Link to="/case-studies">
                <p class="text-white hover:text-gray-900">Case Studies</p>
            </Link>
        </li>
        <li class='mr-6 font-thin'>
            <Link to="/about">
                <p class="text-white hover:text-gray-900">About</p>
            </Link>
        </li>
        <li>
            <a href="https://apps.shopify.com/wellspring-attribution" target="_blank" rel="noopener noreferrer">
                <img src="/appstore.png" class="h-12 w-auto" alt="Shopify App Store Logo"/>
            </a>
        </li>
    </>
)

function Nav() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <nav class="fixed w-full bg-gradient-to-b from-[#4ed0a1] to-[#37a7e5] lg:px-[15%] py-2 z-10">
                <div class="flex items-center justify-between">
                    {/* ws logo always shown */}
                    <div class="flex-shrink-0 mx-4">
                        <Link to="/">
                            <img src="/wswhite.png" class="w-auto h-9" alt="Wellspring Logo"/>
                        </Link>
                    </div>
                    <div class="flex items-center space-x-4 mr-4">
                        {/* hidden button on medium or bigger */}
                        <button class="text-white block md:hidden" onClick={toggleMenu}>
                            <svg
                                class="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    {/* hidden menu items by default on mobile, button instead */}
                    <ol class='hidden md:flex md:items-center'>
                        {menuItems}
                    </ol>
                </div>
                {isOpen && 
                    <ol class='flex flex-col items-end'>
                        {menuItems}
                    </ol>
                }
            </nav>
        </div>
    );
}

export default Nav;