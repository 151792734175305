import './Homepage.css'
import TrustedBy from './components/TrustedBy';
import TryToday from './components/TryToday';
import { useInView } from 'react-intersection-observer';

function Homepage() {
    const { ref: summarySsRef, inView: summarySsVisible } = useInView({threshold:'0.25'});
    const { ref: summaryTitle, inView: summaryTitleVisible } = useInView({threshold:'1'});
    const { ref: summaryPoint1, inView: summaryPoint1Visible } = useInView({threshold:'1'});
    const { ref: summaryPoint2, inView: summaryPoint2Visible } = useInView({threshold:'1'});
    const { ref: avgBrandUsing, inView: avgBrandUsingVisible } = useInView({threshold:'1'});
    const { ref: stats, inView: statsVisible } = useInView({threshold:'1'});
    const { ref: step1, inView: step1Visible } = useInView({threshold:'1'});
    const { ref: step2, inView: step2Visible } = useInView({threshold:'1'});

    // useEffect(() => {
    //     console.log('Element is in view:', summarySsVisible);
    // }, [summarySsVisible]);

    const blueBar = <div class="h-1 w-14 bg-ws-blue mb-2"></div>

    return(
        <div class='pt-12'>
            <div class='flex flex-wrap lg:px-[15%] lg:py-[4%] items-center bg-ws-blue'>
                <div class='md:w-1/3 md:pr-4 m-4'>
                    <h1 class='text-5xl text-center md:text-left my-4'>Don't let ad platforms grade their own homework.</h1>
                    <div class='text-center md:text-left my-8 text-xl'>Keep them honest with Wellspring Attribution's post-purchase survey driven ROAS metrics.</div>
                    <div class='flex justify-center md:justify-start'>
                        <a href="https://apps.shopify.com/wellspring-attribution" target="_blank" rel="noreferrer" class='inline-block bg-black hover:bg-ws-green text-white font-bold py-2 px-4 rounded'>
                            <h4>Get 30 days free</h4>
                        </a>
                    </div>
                </div>
                <div class='lg:flex-1 items-center md:pl-2'>
                    <img class='w-[100%] min-w-300' src='/adperf.png' alt="ad performance and attribution graphs"/>
                </div>
            </div>
            <TrustedBy />
            <div class='flex flex-wrap xl:px-[15%] ml-4 mr-4 mb-4 mt-4'>
                <div ref={summarySsRef} class={`lg:w-2/3 xl:pr-4 transition-opacity duration-1000 ease-in-out ${summarySsVisible ? 'opacity-100' : 'opacity-0'}`}>
                    <img class='w-[100%] min-w-300' src='/summarydrop.png' alt="summary tab screenshot"/>
                </div>
                <div class='lg:w-1/3 flex flex-col'>
                    <h1 ref={summaryTitle} class={`text-4xl text-center ml-6 pb-4 pt-6 md:text-left lg:h-[43%] transition-opacity duration-1000 ease-in-out ${summaryTitleVisible ? 'opacity-100' : 'opacity-0'}`}>Post-purchase survey results turned into a ROAS dashboard</h1>
                    <div ref={summaryPoint1} class={`text-xl text-center ml-6 py-2 md:text-left text-2xl lg:h-[32%] transition-opacity duration-1000 ease-in-out ${summaryPoint1Visible ? 'opacity-100' : 'opacity-0'}`}>
                        {blueBar}
                        <div class='ml-3 text-gray-800'>Let Wellspring ROAS be your North Star – there is no bias toward any one source of revenue</div>
                    </div>
                    <div ref={summaryPoint2} class={`text-xl text-center ml-6 py-2 md:text-left text-2xl lg:h-[25%] transition-opacity duration-1000 ease-in-out ${summaryPoint2Visible ? 'opacity-100' : 'opacity-0'}`}>
                        {blueBar}
                        <div class="ml-3 text-gray-800">Compare Wellspring ROAS metrics side-by-side with platform reported metrics</div>
                    </div>
                </div>
            </div>
            <div class="xl:px-[15%] xl:pb-[2%] xl:pt-[3%]">
                <h1 ref={avgBrandUsing} class={`text-3xl text-center mb-8 transition-opacity duration-1000 ease-in-out ${avgBrandUsingVisible ? 'opacity-100' : 'opacity-0'}`}>The average brand using Wellspring to make spend decisions sees...</h1>
                <div ref={stats} class={`flex justify-center transition-opacity duration-1000 ease-in-out ${statsVisible ? 'opacity-100' : 'opacity-0'}`}>
                    <img class='w-[70%]' src='/stats.png' alt="avg improvements stats"/>
                </div>
            </div>
            <div class='flex flex-wrap xl:px-[15%] xl:py-[3%] ml-4 mr-4 mb-4'>
                <div class='lg:w-2/5 flex flex-col justify-center'>
                    <h1 class='text-3xl font-bold text-center px-10 py-4 md:text-left'>Set up in seconds – no UTMs, pixels, or code</h1>
                    <div ref={step1} class={`text-xl text-center px-10 py-2 md:text-left text-xl transition-opacity duration-1000 ease-in-out ${step1Visible ? 'opacity-100' : 'opacity-0'}`}>
                        {blueBar}
                        <div class="ml-3 text-gray-800">1. Create a custom survey with mapping – mapping ties survey responses (and revenue from corresponding purchases) to ad channels</div>
                    </div>
                </div>
                <div class='lg:w-3/5 xl:pr-10'>
                    <img class='w-[100%] z-2 top-0 left-0' src='/overlaysurvey.png' alt="screenshot of survey setup and result"/>
                </div>
            </div>
            <div class='flex flex-wrap xl:px-[15%] ml-8 mr-4 mb-12'>
                <div class='lg:w-2/3 xl:pr-6'>
                    <img class='w-[100%] z-2 top-0 left-0' src='/integrations.png' alt="integrations menu"/>
                </div>
                <div class='lg:w-1/3 flex flex-col justify-center'>
                    <div ref={step2} class={`text-xl text-center p4-6 py-2 md:text-left text-xl transition-opacity duration-1000 ease-in-out ${step2Visible ? 'opacity-100' : 'opacity-0'}`}>
                        {blueBar}
                        <div class="ml-3 text-gray-800">2. Sign into your digital ad platforms</div>
                    </div>
                </div>
            </div>
            <div class='xl:px-[15%] ml-8 mr-4 mb-12'>
                <div class=''>
                    <h1 class='text-3xl font-bold text-center px-4 pt-[3%] pb-0 text-center'>Discover the power of survey-driven ROAS</h1>
                </div>
                <div class='flex justify-center'>
                    <img class='w-[70%] z-2 top-0 left-0' src='/wsroas.png' alt="wellspring roas graph"/>
                </div>
            </div>
            <TryToday />
        </div>
    );
};

export default Homepage;