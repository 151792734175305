import './TrustedBy.css';

const px = 10;
const brands = (
<>
    <li class='brand'>
        <a href="https://crapeyewear.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/crap.png' alt="crap eyewear logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://naturium.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/naturium.png' alt="naturium logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://bathingculture.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/bathingculture.png' alt="bathing culture logo"/>
        </a>
    </li>
    {/* <li class='brand'>
        <a href="https://khy.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/khy.png' alt="khy logo"/>
        </a>
    </li> */}
    <li class='brand'>
        <a href="https://dphue.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/dp.png' alt="dphue logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://onsentowel.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/onsen.png' alt="onsen towel logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://obeyclothing.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/obey.png' alt="obey clothing logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://elwoodclothing.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/elwood.png' alt="elwood clothing logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://686.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/686.png' alt="686 logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://bradybrand.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/brady.png' alt="brady logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://exponentbeauty.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/exponent_beauty.png' alt="exponent beauty logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://parksproject.us" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/parks.png' alt="parks project logo"/>
        </a>
    </li>
    <li class='brand'>
        <a href="https://evermill.com" target="_blank" rel="noreferrer" >
            <img class="w-[100%]" src='/brands/evermill.png' alt="evermill logo"/>
        </a>
    </li>
</>
);


// we render the brands html twice so that the scroll loops around infinitely
function TrustedBy() {
    return(
        <div class='py-5 overflow-x-hidden bg-gray-100'>
            <div>
                <h3 class='text-md font-bold text-center'>TRUSTED BY TOP SHOPIFY STORES</h3>
            </div>
            <ul class='flex flex-shrink-0 items-center animate-scroll my-4'>
                {brands}
                {brands}
            </ul>
        </div>
    );
};

export default TrustedBy;