import './App.css';
import {Routes, Route} from 'react-router-dom';
import Homepage from './Homepage';
import About from './AboutPage';
import CaseStudies from './CaseStudies';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Nav from './components/Nav';
import Footer from './components/Footer';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <div className="App">
      <Nav />
      <ScrollToTop />
      <Routes>
        <Route path = "/" element={<Homepage />} />
        <Route path = "/about" element={<About />} />
        <Route path = "/case-studies" element={<CaseStudies />} />
        <Route path = "/privacy-policy" element={<PrivacyPolicy />} />
        <Route path = "/terms" element={<TermsOfService />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
