import {Link} from 'react-router-dom';

function Footer() {
    return(
        <div class="h-44 w-full bg-black lg:px-[15%] py-2">
            <div class="flex items-center justify-between h-full">
                <div class="flex-shrink-0 mx-4">
                    <Link to="/">
                        <img src="/wswhite.png" class="w-auto h-14" alt="Wellspring Logo"/>
                    </Link>
                </div>
                <ol class='md:flex flex-col md:items-center text-white text-center mr-4'>
                    <li>
                        <Link to="/privacy-policy">
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link to="/terms">
                            Terms of Use
                        </Link>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default Footer;