import { useInView } from 'react-intersection-observer';

function CaseStudies() {
    const { ref: crapCase, inView: crapCaseVis } = useInView({threshold:'0.5'});
    const { ref: case686, inView: case686Vis } = useInView({threshold:'0.5'});

    return(
        <div class="bg-[#1a1a1a]">
            <div ref={crapCase} class={`lg:px-[15%] flex flex-wrap pt-16 transition-opacity duration-1000 ease-in-out ${crapCaseVis ? 'opacity-100' : 'opacity-0'}`}>
                <img className="w-full inline-block mx-2 py-10" src="/rbcase_study_crap.png" alt="Crap Eyewear Case Study" />
            </div>
            <div ref={case686} class={`lg:px-[15%] flex flex-wrap transition-opacity duration-1000 ease-in-out ${case686Vis ? 'opacity-100' : 'opacity-0'}`}>
                <img className="w-full inline-block mx-2 py-6" src="/case_study_686.png" alt="686 Case Study" />
            </div>
        </div>
    );
};

export default CaseStudies;