import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';

const oarEquation = `\\textbf{Order Attribution Rate = } \\frac{\\text{Number of Survey Responses}}{\\text{Total Shopify Orders}} = \\frac{90}{200} = \\boldsymbol{45.0\\%}`;
const rarEquation = `\\textbf{Revenue Attribution Rate = } \\frac{\\text{Revenue Attached to Responses}}{\\text{Total Shopify Revenue}} = \\frac{\\text{\\$11,200}}{\\text{\\$24,000}} = \\boldsymbol{46.7\\%}`;

const igao = `\\frac{40}{0.450} = \\boldsymbol{88.9}`;
const gao = `\\frac{10}{0.450} = \\boldsymbol{22.2}`;
const tao = `\\frac{25}{0.450} = \\boldsymbol{55.6}`;
const fao = `\\frac{10}{0.450} = \\boldsymbol{22.2}`;
const iao = `\\frac{5}{0.450} = \\boldsymbol{11.1}`;
const totalao = `\\frac{90}{0.450} = \\boldsymbol{200}`;

const igar = `\\frac{\\text{\\$5,000}}{0.467} = \\boldsymbol{\\$10,707}`;
const gar = `\\frac{\\text{\\$1,000}}{0.467} = \\boldsymbol{\\$2,141}`;
const tar = `\\frac{\\text{\\$3,000}}{0.467} = \\boldsymbol{\\$6,424}`;
const far = `\\frac{\\text{\\$1,500}}{0.467} = \\boldsymbol{\\$3,212}`;
const iar = `\\frac{\\text{\\$700}}{0.467} = \\boldsymbol{\\$1,499}`;
const totalar = `\\frac{\\text{\\$11,200}}{0.467} = \\boldsymbol{\\$24,000}`;

const mroas = `\\frac{\\$10,707}{\\$4,000} = \\boldsymbol{268\\%}`;
const groas = `\\frac{\\$2,141}{\\$650} = \\boldsymbol{329\\%}`;
const troas = `\\frac{\\$6,424}{\\$1,750} = \\boldsymbol{367\\%}`;
const totalroas = `\\frac{\\$19,272}{\\$6,400} = \\boldsymbol{301\\%}`;

const mcpp = `\\frac{\\$4,000}{88.9} = \\boldsymbol{\\$44.99}`;
const gcpp = `\\frac{\\$650}{22.2} = \\boldsymbol{\\$29.28}`;
const tcpp = `\\frac{\\$1,750}{55.6} = \\boldsymbol{\\$31.47}`;
const totalcpp = `\\frac{\\$6,400}{166.7} = \\boldsymbol{\\$38.39}`;

const blueBar = <div class="h-1 w-14 bg-ws-blue mb-2"></div>

function About() {
    // top slicer
    const { ref: topSlice, inView: topSliceVis } = useInView({threshold:'0.1'});
    const [tsHasFadedIn, setTsHasFadedIn] = useState(false);

    useEffect(() => {
        if (topSliceVis && !tsHasFadedIn) {
        setTsHasFadedIn(true);
        }
    }, [topSliceVis, tsHasFadedIn]);

    // slice2
    const { ref: slice2, inView: slice2Vis } = useInView({threshold:'0.1'});
    const [s2HasFadedIn, sets2HasFadedIn] = useState(false);

    useEffect(() => {
        if (slice2Vis && !s2HasFadedIn) {
        sets2HasFadedIn(true);
        }
    }, [slice2Vis, s2HasFadedIn]);

    // slice3
    const { ref: slice3, inView: slice3Vis } = useInView({threshold:'0.99'});
    const [s3HasFadedIn, sets3HasFadedIn] = useState(false);

    useEffect(() => {
        if (slice3Vis && !s3HasFadedIn) {
        sets3HasFadedIn(true);
        }
    }, [slice3Vis, s3HasFadedIn]);

    // slice4
    const { ref: slice4, inView: slice4Vis } = useInView({threshold:'0.5'});
    const [s4HasFadedIn, sets4HasFadedIn] = useState(false);

    useEffect(() => {
        if (slice4Vis && !s4HasFadedIn) {
        sets4HasFadedIn(true);
        }
    }, [slice4Vis, s4HasFadedIn]);

    // slice5
    const { ref: slice5, inView: slice5Vis } = useInView({threshold:'0.5'});
    const [s5HasFadedIn, sets5HasFadedIn] = useState(false);

    useEffect(() => {
        if (slice5Vis && !s5HasFadedIn) {
        sets5HasFadedIn(true);
        }
    }, [slice5Vis, s5HasFadedIn]);

    return(
        <div>
            <div class="xl:px-[15%] lg:pt-[6%] lg:pb-[3%] flex flex-wrap pt-16 bg-ws-blue bg-opacity-40 text-center">
                <p class="text-2xl text-center mx-auto font-thin text-white">How Wellspring Works</p>
                <img ref={topSlice} class={`w-full min-w-300 transition-opacity duration-[2000ms] ease-in-out delay-500 ${topSliceVis || tsHasFadedIn ? 'opacity-100' : 'opacity-0'}`} src='/abouttopslice.png' alt="survey ads attribution icons"/>
            </div>
            <div class="px-[1%] lg:px-[15%] py-[3%]">
                {/* <div class="text-3xl text-center mx-auto pb-[4%]">
                    <h1>The Math Behind Our 
                    <img className="w-12 inline-block border-gray-600 rounded-lg border-2 mx-2" src="/w.png" alt="Wellspring W" />
                    Metrics:</h1>
                </div> */}
                <div ref={slice2} class={`flex flex-wrap xl:w-[85%] xl:mx-auto transition-opacity duration-[1000ms] ease-in-out delay-[1400ms] ${slice2Vis || s2HasFadedIn ? 'opacity-100' : 'opacity-0'}`}>
                    <div class="flex flex-col mx-4 md:w-[40%]">
                        <div class="w-auto ml-auto md:mr-[8%]">
                            {blueBar}
                            <h4 class="text-left text-xl mb-4 mx-4">Example</h4>
                            <ol class="text-lg list-disc ml-8 mb-4">
                                <li class="my-1">The Shopify store saw 200 orders</li>
                                <li class="my-1">The 200 orders made for $24,000 in revenue</li>
                                <li class="my-1">Of the 200 orders, 90 of them completed the post-purchase survey (eg. "How did you hear about us?") in this distribution</li>
                            </ol>
                        </div>
                    </div>
                    <div class="flex flex-1 flex-col w-[60%]">
                        <div class="overflow-hidden rounded-lg mx-auto xl:mx-0 xl:mr-auto shadow-2xl"> {/* div that encloses the table */}
                            <table class="table-auto border-collapse border border-gray-300">
                                <thead>
                                    <tr class="bg-ws-blue bg-opacity-30">
                                        <th class="border border-gray-300 px-4 py-2 text-left">Response</th>
                                        <th class="border border-gray-300 px-4 py-2 text-left">Number of Responses</th>
                                        <th className="border border-gray-300 px-4 py-2 text-left">
                                            Revenue from orders with <br />given survey response
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="border border-gray-300 px-4 py-2">Instagram Ad</td>
                                        <td class="border border-gray-300 px-4 py-2">40</td>
                                        <td class="border border-gray-300 px-4 py-2">$5,000</td>
                                    </tr>
                                    <tr class="bg-ws-blue bg-opacity-10">
                                        <td class="border border-gray-300 px-4 py-2">Google Ad</td>
                                        <td class="border border-gray-300 px-4 py-2">10</td>
                                        <td class="border border-gray-300 px-4 py-2">$1,000</td>
                                    </tr>
                                    <tr>
                                        <td class="border border-gray-300 px-4 py-2">TikTok Ad</td>
                                        <td class="border border-gray-300 px-4 py-2">25</td>
                                        <td class="border border-gray-300 px-4 py-2">$3,000</td>
                                    </tr>
                                    <tr class="bg-ws-blue bg-opacity-10">
                                        <td class="border border-gray-300 px-4 py-2">Friend</td>
                                        <td class="border border-gray-300 px-4 py-2">10</td>
                                        <td class="border border-gray-300 px-4 py-2">$1,500</td>
                                    </tr>
                                    <tr>
                                        <td class="border border-gray-300 px-4 py-2">Influencer</td>
                                        <td class="border border-gray-300 px-4 py-2">5</td>
                                        <td class="border border-gray-300 px-4 py-2">$700</td>
                                    </tr>
                                    <tr class="font-bold bg-ws-blue bg-opacity-30">
                                        <td class="border border-gray-300 px-4 py-2">Total</td>
                                        <td class="border border-gray-300 px-4 py-2">90</td>
                                        <td class="border border-gray-300 px-4 py-2">$11,200</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="w-[85%] mx-auto md:mx-0 md:mr-auto text-center md:text-left text-l my-2">
                            <i>*In addition to the number of responses for each answer, Wellspring also pulls the revenue specific to those responses, directly from each respective Shopify order.</i>
                        </div>
                    </div>
                </div>
                <div class="flex w-full mt-10">
                    <div class="flex flex-col w-auto mt-6 mx-auto">
                        {blueBar}
                        <div class="w-auto mx-auto text-center text-xl ml-4 mb-10">
                            <h4>This gives two key response rate metrics:</h4>
                        </div>
                    </div>  
                </div>
                <div ref={slice3} class={`flex flex-col list-disc mx-auto mb-4 text-[8px] transition-opacity duration-[1000ms] ease-in-out ${slice3Vis || s3HasFadedIn ? 'opacity-100' : 'opacity-0'}`}>
                    <div className="mx-auto text-center sm:text-[8px] md:text-sm xl:text-lg">
                        <BlockMath math={oarEquation} />
                    </div>
                    <div className="mx-auto text-center sm:text-[8px] md:text-sm xl:text-lg">
                        <BlockMath math={rarEquation} />
                    </div>
                </div>
                <div ref={slice4} class={`flex flex-wrap my-16 xl:w-[85%] xl:mx-auto transition-opacity duration-[1000ms] ease-in-out ${slice4Vis || s4HasFadedIn ? 'opacity-100' : 'opacity-0'}`}> 
                    <div class="flex flex-col flex-1 md:w-[60%] md:mx-[2%]">
                        <div class="overflow-hidden rounded-lg shadow-2xl mx-auto lg:ml-auto"> {/* div that encloses the table */}
                            <table className="table-auto border-collapse border border-gray-300">
                                <thead class="text-xs md:text-md">
                                <tr className="bg-ws-blue bg-opacity-30">
                                    <th className="border border-gray-300 px-4 py-2 text-left">Response</th>
                                    <th className="border border-gray-300 px-4 py-2 text-left">
                                        <div className="flex items-center">
                                            <img className="w-8 mr-2" src="/w.png" alt="Wellspring W" />
                                            <span>Attributed Orders</span>
                                        </div>
                                    </th>
                                    <th className="border border-gray-300 px-4 py-2 text-left">
                                        <div className="flex items-center">
                                            <img className="w-8 mr-2" src="/w.png" alt="Wellspring W" />
                                            <span>Attributed Revenue</span>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2 text-sm">Instagram Ad</td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={igao} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={igar} /></td>
                                </tr>
                                <tr className="bg-ws-blue bg-opacity-10">
                                    <td className="border border-gray-300 px-4 py-2 text-sm">Google Ad</td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={gao} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={gar} /></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2 text-sm">TikTok Ad</td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={tao} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={tar} /></td>
                                </tr>
                                <tr className="bg-ws-blue bg-opacity-10">
                                    <td className="border border-gray-300 px-4 py-2 text-sm">Friend</td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={fao} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={far} /></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2 text-sm">Influencer</td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={iao} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={iar} /></td>
                                </tr>
                                <tr className="font-bold bg-ws-blue bg-opacity-30 text-sm">
                                    <td className="border border-gray-300 px-4 py-2">Total</td>
                                    <td className="border border-gray-300 px-4 py-2 test-xs"><BlockMath math={totalao} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-xs"><BlockMath math={totalar} /></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class='text-left text-xl md:w-[40%] mt-5 md:mt-0 mx-auto 2xl:mr-auto'>
                        {blueBar}
                        <div class='ml-4'>
                            <h4>Extrapolation</h4>
                            <div>Wellspring assumes that the distribution of responses is a representative sample of where all of the Shopify revenue should be attributed</div>
                        </div>
                    </div>
                </div>
                <div ref={slice5} class={`flex flex-wrap my-20 xl:w-[85%] xl:mx-auto transition-opacity duration-[1000ms] ease-in-out ${slice5Vis || s5HasFadedIn ? 'opacity-100' : 'opacity-0'}`}> {/*Row with ROAS & CPP table*/}
                    <div class="flex flex-col flex-1 md:w-[50%] text-left text-xl 2xl:mr-[4%]">
                        {blueBar}
                        <div class="mx-4">
                            <h4>Resulting ROAS & CPP Calculations</h4>
                            The result is a full picture of where <i>every</i> order heard about the brand, modeled off of the 90 survey responses for $12,200 in revenue that we can directly attribute. 
                            <br />
                            <br />
                            Wellspring ROAS and Wellspring CPP by channel and in total follow easily from the above, once we plug in the ad spend values, which Wellspring pulls automatically
                        </div>
                    </div>
                    <div class="flex flex-col flex-1 mx-auto md:w-[60%] ml-auto">
                        <div class="overflow-auto md:mx-auto rounded-lg shadow-2xl"> {/* div that encloses the table */}
                            <table className="table-auto border-collapse border border-gray-300">
                                <thead class="text-xs md:text-md">
                                <tr className="bg-ws-blue bg-opacity-30">
                                    <th className="border border-gray-300 px-4 py-2 text-left">Digital Ad Platform</th>
                                    <th className="border border-gray-300 px-4 py-2 text-left">Spend</th>
                                    <th className="border border-gray-300 px-4 py-2 text-left">
                                        <div className="flex items-center">
                                            <img className="w-7 mr-2" src="/w.png" alt="Wellspring W" />
                                            <span>ROAS</span>
                                        </div>
                                    </th>
                                    <th className="border border-gray-300 px-4 py-2 text-left">
                                        <div className="flex items-center">
                                            <img className="w-7 mr-2" src="/w.png" alt="Wellspring W" />
                                            <span>Cost Per Purchase</span>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody class="text-xs md:text-lg">
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2">Meta</td>
                                    <td className="border border-gray-300 px-4 py-2">$4,000</td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={mroas} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={mcpp} /></td>
                                </tr>
                                <tr className="bg-ws-blue bg-opacity-10">
                                    <td className="border border-gray-300 px-4 py-2">Google</td>
                                    <td className="border border-gray-300 px-4 py-2">$650</td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={groas} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={gcpp} /></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2">TikTok</td>
                                    <td className="border border-gray-300 px-4 py-2">$1,750</td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={troas} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={tcpp} /></td>
                                </tr>
                                <tr className="bg-ws-blue bg-opacity-30">
                                    <td className="border border-gray-300 px-4 py-2"><strong>Total Blended</strong></td>
                                    <td className="border border-gray-300 px-4 py-2"><strong>$6,400</strong></td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={totalroas} /></td>
                                    <td className="border border-gray-300 px-4 py-2 text-[9px] md:text-sm"><BlockMath math={totalcpp} /></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;