function TryToday() {
    return(
        <div class="h-40 w-full bg-gradient-to-b from-[#4ed0a1] to-[#37a7e5] lg:px-[15%] py-2">
            <div class="flex items-center justify-center h-full">
                <div class='flex justify-center md:justify-start mx-4'>
                    <a href="https://apps.shopify.com/wellspring-attribution" target="_blank" rel="noreferrer" class='inline-block bg-black hover:bg-ws-green text-white font-bold py-2 px-4 rounded'>
                        <h4>Get 30 days free</h4>
                    </a>
                </div>
                <div class="flex-shrink-0">
                    <a href="https://apps.shopify.com/wellspring-attribution" target="_blank" rel="noreferrer">
                        <img src="/appstore.png" class="w-auto h-16" alt="Shopify App Store Logo"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TryToday;